<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CInput
                    placeholder="Firma İsmi"
                    type="text"
                    autocomplete="text"
                    v-model="data.name"
                    feedbackInvalid="You must agree before submitting."
                    required
                    invalid
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CInput>
            </CCol>
            <CCol>
                <CRow>
                    <CCol tag="label" sm="2" class="col-form-label">
                        <b>Aktif</b>
                    </CCol>
                    <CCol sm="3">
                        <CSwitch
                        size="lg"
                        color="primary"
                        :checked="data.activeFlag"
                        v-model="data.activeFlag"
                        @update:checked="setSwitch('activeFlag')"
                        />
                    </CCol>
                    <CCol tag="label" sm="2" class="col-form-label">
                        <b>Default Flag</b>
                    </CCol>
                    <CCol sm="3">
                        <CSwitch
                        size="lg"
                        color="primary"
                        :checked="data.defaultFlag"
                        v-model="data.defaultFlag"
                        @update:checked="setSwitch('defaultFlag')"
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
        <CCol>
            <CInput
            placeholder="Website"
            type="text"
            autocomplete="text"
            v-model="data.webSiteUrl"
            >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
            </CInput>
        </CCol>
        <CCol>
            <CInput
            placeholder="ABBR"
            type="text"
            :maxlength="5"
            autocomplete="text"
            v-model="data.abbr"
            >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
            </CInput>
        </CCol>
        </CRow>
        <CRow>
        <CCol>
            <CInput
            placeholder="Api Customer Code"
            type="text"
            autocomplete="text"
            v-model="data.apiCustomerCode"
            >
                <template #prepend-content>
                    <CIcon name="cilTextSquare" />
                </template>
            </CInput>
        </CCol>
        <CCol>
            <CInput
            placeholder="Api Password"
            type="text"
            autocomplete="text"
            v-model="data.apiPassword"
            >
                <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                </template>
            </CInput>
        </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                placeholder="Api Type"
                type="text"
                autocomplete="text"
                v-model="data.apiType"
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CInput>
            </CCol>
            <CCol>
                <CInput
                placeholder="Api Username"
                type="text"
                autocomplete="text"
                v-model="data.apiUserName"
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                placeholder="Contact Person"
                type="text"
                autocomplete="text"
                v-model="data.contactPerson"
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CInput>
            </CCol>
            <CCol>
                <CInput
                placeholder="Contact Phone Number"
                type="text"
                autocomplete="text"
                v-model="data.contactPhoneNumber"
                >
                    <template #prepend-content>
                        <CIcon name="cil-phone" />
                    </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow>        
            <CCol>
                <CSelect
                label="Teslimat Başlangıç Günü"
                :options="days"
                v-model="data.startWeekDay"
                @change="setTimeInfo('startWeekDay', $event)"
                placeholder="Teslimat Başlangıç Günü"
                >
                    <template #prepend-content>
                        <CIcon name="cilClock" />
                    </template>
                </CSelect>
            </CCol>
            <CCol>
                <CInput
                label="Teslimat Başlangıç Saati"
                placeholder="Teslim. Baş. Saati"
                type="time"
                autocomplete="time"
                v-model="data.startHour"
                >
                    <template #prepend-content>
                        <CIcon name="cilClock" />
                    </template>
                </CInput>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CSelect
                label="Teslimat Bitiş Günü"
                :options="days"
                v-model="data.endWeekDay"
                @change="setTimeInfo('endWeekDay', $event)"
                placeholder="Teslimat Bitiş Günü"
                >
                    <template #prepend-content>
                        <CIcon name="cilClock" />
                    </template>
                </CSelect>
            </CCol>

            <CCol>
                <CInput
                label="Teslimat Bitiş Saati"
                placeholder="Teslimat Bitiş Saati"
                type="time"
                autocomplete="time"
                v-model="data.endHour"
                >
                    <template #prepend-content>
                        <CIcon name="cilClock" />
                    </template>
                </CInput>
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "CargoForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                days: [
                    'Gün Seçiniz',
                    { label: "Pazartesi", value: 1},
                    { label: "Salı", value: 2},
                    { label: "Çarşamba", value: 3},
                    { label: "Perşembe", value: 4},
                    { label: "Cuma", value: 5},
                    { label: "Cumartesi", value: 6},
                    { label: "Pazar", value: 7}
                ]
            }
        },
        methods:{
            setTimeInfo(data, item) {
                this.data[data] = item.target.value
            },
            setSwitch(data) {
                this.data[data] = !this.data[data]
            },
        }
    }
</script>